<template>
  <div class="card">
    <!-- <p class="title">屋脊装饰物</p> -->
    <p class="title">{{ isEdit ? "新增" : "修改" }}屋脊装饰物</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <van-field
        @keydown="handleInput"
        v-model.number="form.Height"
        input-align="right"
        label="装饰物高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写装饰物高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.Width"
        input-align="right"
        label="装饰物宽度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写装饰物宽度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.WestSideDistance"
        input-align="right"
        label="距西侧距离（m）"
        type="number"
        :rules="[{ required: true, message: '请填写距西侧距离' }]"
        @focus="$event.target.select()"
      />
      <div class="item">
        <van-field label="是否可移除" input-align="right" readonly />
        <span @click.stop="IsRemovableTypeShow = true"
        >{{ form.TrueOrFalseRemove }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options twoline" v-show="IsRemovableTypeShow">
          <li @click="chooseIsRemovable('是')">是</li>
          <li @click="chooseIsRemovable('否')">否</li>
        </ul>
      </div>
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <div class="cancel" v-if="!isEdit" @click="del">删除</div>
        <van-button v-if="isEdit" native-type="submit" class="determine"> 确定 </van-button>
        <span
          v-if="!isEdit"
          @click="editDetermine"
          class="determine"
        >确定</span
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { guid } from '@/plugins/guid';
import emitter from '../../plugins/eventBus'

export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    // const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const data = reactive({
      form: {
        id: guid(),
        roofID: guid(),
        Height: '', // 装饰物高度（CM）
        Width: '', // 装饰物宽度（CM）
        WestSideDistance: '', // 距西侧距离（CM）
        TrueOrFalseRemove: '否' // 是否可移除  1：可移除  2：不可移除
      },
      IsRemovableTypeShow: false,
      isEdit: true,
      hort: {},

    })
    emitter.on('editOrnament', (value) => {
      data.hort = value
      data.isEdit = false

      data.form.Height = data.hort.Height / 100
      data.form.Width = data.hort.Width / 100
      data.form.WestSideDistance = data.hort.WestSideDistance / 100
      if (data.hort.TrueOrFalseRemove === 1) {
        data.form.TrueOrFalseRemove = '是'
      } else {
        data.form.TrueOrFalseRemove = '否'
      }
    })
    emitter.on('editstateOrnament', (val) => {
      if (val === 1) {
        data.isEdit = false
      } else if (val === 2) {
        data.isEdit = true
      }
    })

    onMounted(() => {
      window.addEventListener('click', methodsMap.handleBodyClick)
    })
    const methodsMap = {
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },
      editDetermine() {
        if (data.isEdit === false) {
          const dataTo2d = {}
          data.hort.Height = data.form.Height * 100
          data.hort.Width = data.form.Width * 100
          data.hort.WestSideDistance = data.form.WestSideDistance * 100
          if (data.form.TrueOrFalseRemove === '是') {
            data.hort.TrueOrFalseRemove = 1
          } else {
            data.hort.TrueOrFalseRemove = 2
          }

          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑屋脊装饰物" })
          }
          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑斜屋顶屋脊装饰物" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑内厦屋顶屋脊装饰物" })
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑外厦带平台屋顶屋脊装饰物" })
          }

          emit('popupClose', dataTo2d)

          data.form = {
            Height: '',
            Width: '',
            WestSideDistance: '',
            TrueOrFalseRemove: '否'
          }
        }
      },
      handleBodyClick() {
        data.IsRemovableTypeShow = false
      },
      chooseIsRemovable(item) {
        data.form.TrueOrFalseRemove = item
        data.IsRemovableTypeShow = false
      },

      onSubmit() {
        if (data.isEdit === true) {
          const dataTo2d = {}
          for (const i in data.form) {
            if (i !== 'id' && i !== 'roofID' && i !== 'TrueOrFalseRemove') {
              data.form[i] = data.form[i] * 100
            }
          }
          // Object.assign(dataTo2d, data.form, { name: "屋脊装饰物" })
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.form, { name: "屋脊装饰物" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.form, { name: "斜屋顶屋脊装饰物" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.form, { name: "内厦屋顶屋脊装饰物" })
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.form, { name: "外厦带平台屋顶屋脊装饰物" })
          }
          emit('popupClose', dataTo2d)

          data.form = {
            Height: '',
            Width: '',
            WestSideDistance: '',
            TrueOrFalseRemove: '否'
          }
        }
      },
      del() {
        const dataTo2d = {}
        if (sessionStorage.getItem('roofType') === '平屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除屋脊装饰物" })
        }

        if (sessionStorage.getItem('roofType') === '斜屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除斜屋顶屋脊装饰物" })
        }

        if (sessionStorage.getItem('roofType') === '内厦屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除内厦屋顶屋脊装饰物" })
        }

        if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除外厦带平台屋顶屋脊装饰物" })
        }

        emit('popupClose', dataTo2d)
        data.isEdit = true
        data.form = {
          Height: '',
          Width: '',
          WestSideDistance: '',
          TrueOrFalseRemove: '否'
        }
      },
      cancelEvent() {
        emit('popupClose')
        data.isEdit = true
        data.form = {
          Height: '',
          Width: '',
          WestSideDistance: '',
          TrueOrFalseRemove: '否'
        }
      }
    }

    return {
      ...toRefs(data),
      ...methodsMap,
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 312px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      color: #323233;
      font-weight: 700;
      width: 52px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .options {
      z-index: 1;
      width: 52px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      color: #c7c9cc;
      li {
        line-height: 20px;
        font-weight: 700;
        color: #1A1A1A;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>
