<template>
  <div class="card">
    <p class="title">基本信息</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <!-- <van-field
        v-model.number="form.EastWestLength"
        input-align="right"
        label="东西长度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写东西长度' }]"
      />
      <van-field
        v-model.number="form.NorthSouthWidth"
        input-align="right"
        label="南北宽度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写南北宽度' }]"
      /> -->
      <!-- <van-field
        v-model.number="form.SouthSlopeWidth"
        input-align="right"
        label="南坡宽度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写南坡宽度' }]"
      /> -->
      <van-field
        v-model.number="form.southSlope"
        input-align="right"
        label="南坡坡度（°）"
        :rules="[{ required: true, message: '请填写南坡坡度' }]"
      />
      <!-- <van-field
        v-model.number="form.NorthSlopeWidth"
        input-align="right"
        label="北坡宽度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写北坡宽度' }]"
      /> -->
      <van-field
        v-model.number="form.northSlope"
        input-align="right"
        label="北坡坡度（°）"
        type="number"
        :rules="[{ required: true, message: '请填写北坡坡度' }]"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.eavesHigh"
        input-align="right"
        label="屋檐高度（m）"
        :formatter="formatter"
        :rules="[{ required: true, message: '请填写屋檐高度' }]"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.houseHeight"
        input-align="right"
        label="房屋高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写房屋高度' }]"
      />
      <div class="item">
        <van-field label="房屋朝向" input-align="right" readonly />
        <span @click.stop="houseOrientationTypeShow = true"
        >{{ form.houseFacing }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options" v-show="houseOrientationTypeShow">
          <li @click="chooseHouseOrientationType('南偏东')">南偏东</li>
          <li @click="chooseHouseOrientationType('正南')">正南</li>
          <li @click="chooseHouseOrientationType('南偏西')">南偏西</li>
        </ul>
      </div>
      <van-field
        v-if="form.houseFacing !== '正南'"
        v-model.number="form.facingDegree"
        input-align="right"
        label="偏向度数"
        type="number"
        :rules="[{ required: true, message: '请填写偏向度数' }]"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.gridConnectionDistance"
        input-align="right"
        label="距并网点距离（m）"
        type="number"
        :rules="[{ required: true, message: '请填写距并网点距离' }]"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.ridgeHigh"
        input-align="right"
        label="屋脊高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写屋脊高度' }]"
      />
      <div class="item">
        <van-field label="屋面类型" input-align="right" readonly />
        <span @click.stop="roofTypeShow = true"
        >{{ form.roofingType }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options fourline" v-show="roofTypeShow">
          <li @click="chooseRoofTypeShow('预制板')">预制板</li>
          <li @click="chooseRoofTypeShow('现浇板')">现浇板</li>
          <li @click="chooseRoofTypeShow('木望板')">木望板</li>
          <li @click="chooseRoofTypeShow('凛橼结构')">凛橼结构</li>
        </ul>
      </div>
      <van-field
        @keydown="handleInput"
        v-if="form.roofingType == '预制板' || form.roofingType == '现浇板'"
        v-model.number="form.roofSlabThickness"
        input-align="right"
        type="number"
        label="屋面厚度（m）"
        :rules="[{ required: true, message: '请填写屋面厚度' }]"
      />
      <!-- 木望板 -->
      <div v-if="form.roofingType == '木望板'">
        <van-field
          @keydown="handleInput"
          v-model.number="form.woodPurlinDiameter"
          input-align="right"
          label="木檩直径（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木檩直径' }]"
        />
        <van-field
          @keydown="handleInput"
          v-model.number="form.woodPurlinSpacing"
          input-align="right"
          label="木檩间距（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木檩间距' }]"
        />
        <van-field
          @keydown="handleInput"
          v-model.number="form.woodWatchboardThickness"
          input-align="right"
          label="木望板厚度（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木望板厚度' }]"
        />
      </div>
      <!-- 凛橼结构 -->
      <div v-if="form.roofingType == '凛橼结构'">
        <van-field
          @keydown="handleInput"
          v-model.number="form.purlinDiameter"
          input-align="right"
          label="木檩条直径（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木檩条直径' }]"
        />
        <van-field
          @keydown="handleInput"
          v-model.number="form.purlinSpacing"
          input-align="right"
          label="木檩条间距（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木檩条间距' }]"
        />
        <van-field
          @keydown="handleInput"
          v-model.number="form.herringboneBeamEastWest"
          input-align="right"
          label="人字梁东西跨度（m）"
          type="number"
          :rules="[{ required: true, message: '请填写人字梁东西跨度' }]"
        />
        <van-field
          @keydown="handleInput"
          v-model.number="form.herringboneBeamNorthSouth"
          input-align="right"
          label="人字梁南北跨度（m）"
          type="number"
          :rules="[{ required: true, message: '请填写人字梁南北跨度' }]"
        />
        <van-field
          @keydown="handleInput"
          v-model.number="form.herringboneGirderDiameter"
          input-align="right"
          label="人字梁主梁直径（m）"
          type="number"
          :rules="[{ required: true, message: '请填写人字梁主梁直径' }]"
        />
      </div>
      <div class="item">
        <van-field label="安装方式" input-align="right" readonly />
        <span @click.stop="installationModeTypeShow = true"
        >{{ form.installationMethod }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options twoline" v-show="installationModeTypeShow">
          <li @click="chooseInstallationMode('前支背拉')">前支背拉</li>
          <li @click="chooseInstallationMode('挂钩')">挂钩</li>
        </ul>
      </div>
      <!-- <div class="item">
        <van-field label="南侧屋檐" input-align="right" readonly />
        <span @click.stop="SouthroofEndTypeShow = true"
        >{{ form.SouthroofEnd }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options fourline" v-show="SouthroofEndTypeShow">
          <li @click="chooseSouthroofEnd('标准前支')">标准前支</li>
          <li @click="chooseSouthroofEnd('无水平杆前支')">无水平杆前支</li>
          <li @click="chooseSouthroofEnd('前排挂钩')">前排挂钩</li>
          <li @click="chooseSouthroofEnd('全挂钩')">全挂钩</li>
        </ul>
      </div>
      <div class="item">
        <van-field label="屋脊可探出" input-align="right" readonly />
        <span @click.stop="RidgeofRoofPokesOutTypeShow = true"
        >{{ form.RidgeofRoofPokesOut }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options threeline" v-show="RidgeofRoofPokesOutTypeShow">
          <li @click="chooseRidgeofRoofPokesOut('0mm')">0mm</li>
          <li @click="chooseRidgeofRoofPokesOut('500mm')">500mm</li>
          <li @click="chooseRidgeofRoofPokesOut('800mm')">800mm</li>
        </ul>
      </div>
      <div class="item">
        <van-field label="北侧屋檐" input-align="right" readonly />
        <span @click.stop="NorthroofEndTypeShow = true"
        >{{ form.NorthroofEnd }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options twoline" v-show="NorthroofEndTypeShow">
          <li @click="chooseNorthroofEnd('标准背拉')">标准背拉</li>
          <li @click="chooseNorthroofEnd('挂钩背拉')">挂钩背拉</li>
        </ul>
      </div> -->
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <van-button native-type="submit" class="determine"> 确定 </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from 'vue'
import util from '@/plugins/util'
export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    const formatter = (v) => {
      return util.formatNumber(v)
    }
    const globleData = getCurrentInstance().appContext.app.config
      .globalProperties.$globleData
    const form = reactive({
      roofType: 1, // 斜屋顶类型//1斜屋顶 2内厦 3 外厦
      // EastWestLength: "", // 东西长度
      // NorthSouthWidth: "", // 南北宽度
      southSlopeWidth: "", // 南坡宽度
      southSlope: "", // 南坡坡度
      northSlopeWidth: "", // 北坡宽度
      northSlope: "", // 北坡坡度
      eavesHigh: "", // 屋檐高度
      topViewPic: "",
      sideViewPic: "",
      rearViewPic: "",
      frontViewPic: "",
      houseHeight: "", // 房屋高度
      houseFacing: '正南', // 房屋朝向
      facingDegree: "", // 偏向度数
      gridConnectionDistance: "", // 距并网点距离
      ridgeHigh: "", // 屋脊高度
      roofingType: '预制板', // 屋面类型
      roofSlabThickness: "", // 屋面厚度
      woodPurlinDiameter: "", // 木檩直径（CM）
      woodPurlinSpacing: "", // 木檩间距（CM）
      woodWatchboardThickness: "", // 木望板厚度（CM）
      purlinDiameter: "", // 木檩条直径（CM）
      purlinSpacing: "", // 木檩条间距（CM）
      herringboneBeamEastWest: "", // 人字梁东西跨度（CM）
      herringboneBeamNorthSouth: "", // 人字梁南北跨度（CM）
      herringboneGirderDiameter: "", // 人字梁主梁直径（CM）
      installationMethod: '前支背拉', // 安装方式
      SouthroofEnd: '标准前支', // 南侧屋檐
      RidgeofRoofPokesOut: '0mm', // 屋脊可探出
      NorthroofEnd: '标准背拉' // 北侧屋檐
    })
    const houseOrientationTypeShow = ref(false)
    const roofTypeShow = ref(false)
    const installationModeTypeShow = ref(false)
    const SouthroofEndTypeShow = ref(false)
    const RidgeofRoofPokesOutTypeShow = ref(false)
    const NorthroofEndTypeShow = ref(false)
    window.addEventListener('click', handleBodyClick)
    onMounted(() => {
      console.log(JSON.parse(sessionStorage.getItem('slopingAgain')), '斜屋顶基础数据')
      var dataInfo = JSON.parse(sessionStorage.getItem('slopingAgain'))
      var basedata = JSON.parse(sessionStorage.getItem('baseInfo'))
      if (basedata !== null) {
        form.southSlopeWidth = basedata.southSlopeWidth
        form.southSlope = basedata.southSlope
        form.northSlopeWidth = basedata.northSlopeWidth
        form.northSlope = basedata.northSlope
        form.eavesHigh = basedata.eavesHigh
        form.topViewPic = basedata.topViewPic
        form.sideViewPic = basedata.sideViewPic
        form.rearViewPic = basedata.rearViewPic
        form.frontViewPic = basedata.frontViewPic
        form.houseHeight = basedata.houseHeight
        form.houseFacing = basedata.houseFacing
        form.SouthroofEnd = basedata.SouthroofEnd
        form.RidgeofRoofPokesOut = basedata.RidgeofRoofPokesOut
        form.NorthroofEnd = basedata.NorthroofEnd
        if (basedata.southRoofEnd === 1) {
          form.SouthroofEnd = '标准前支'
        } else if (basedata.southRoofEnd === 2) {
          form.SouthroofEnd = '无水平杆前支'
        } else if (basedata.southRoofEnd === 3) {
          form.SouthroofEnd = '前排挂钩'
        } else if (basedata.southRoofEnd === 4) {
          form.SouthroofEnd = '全挂钩'
        }
        if (basedata.ridgeofRoofPokesOut === 1) {
          form.RidgeofRoofPokesOut = '0mm'
        } else if (basedata.ridgeofRoofPokesOut === 2) {
          form.RidgeofRoofPokesOut = '500mm'
        } else if (basedata.ridgeofRoofPokesOut === 3) {
          form.RidgeofRoofPokesOut = '800mm'
        }
        if (basedata.northRoofEnd === 1) {
          form.NorthroofEnd = '标准背拉'
        } else if (basedata.northRoofEnd === 2) {
          form.NorthroofEnd = '挂钩背拉'
        }
        if (basedata.houseFacing === 1) {
          form.houseFacing = "正南"
        } else if (basedata.houseFacing === 2) {
          form.houseFacing = "南偏东"
        } else if (basedata.houseFacing === 3) {
          form.houseFacing = "南偏西"
        }
        form.facingDegree = basedata.facingDegree
        form.gridConnectionDistance = basedata.gridConnectionDistance
        form.ridgeHigh = basedata.ridgeHigh
        form.roofingType = basedata.roofingType
        if (basedata.roofingType === 1) {
          form.roofingType = "现浇板"
        } else if (basedata.roofingType === 2) {
          form.roofingType = "预制板"
        } else if (basedata.roofingType === 3) {
          form.roofingType = "木望板"
        } else if (basedata.roofingType === 4) {
          form.roofingType = "凛橼结构"
        }
        form.roofSlabThickness = basedata.roofSlabThickness
        form.woodPurlinDiameter = basedata.woodPurlinDiameter
        form.woodPurlinSpacing = basedata.woodPurlinSpacing
        form.woodWatchboardThickness = basedata.woodWatchboardThickness
        form.purlinDiameter = basedata.purlinDiameter
        form.purlinSpacing = basedata.purlinSpacing
        form.herringboneBeamEastWest = basedata.herringboneBeamEastWest
        form.herringboneBeamNorthSouth = basedata.herringboneBeamNorthSouth
        form.herringboneGirderDiameter = basedata.herringboneGirderDiameter
        form.installationMethod = basedata.installationMethod
        if (basedata.installationMethod === 1) {
          form.installationMethod = "前支背拉"
        } else if (basedata.installationMethod === 2) {
          form.installationMethod = "挂钩"
        }
      } else {
        if (dataInfo !== null && dataInfo.obliqueRoofBasicModel !== null) {
          var infoData = dataInfo.obliqueRoofBasicModel
          form.southSlopeWidth = infoData.southSlopeWidth / 1000
          form.southSlope = infoData.southSlope
          form.northSlopeWidth = infoData.northSlopeWidth / 1000
          form.northSlope = infoData.northSlope
          form.eavesHigh = infoData.eavesHigh / 1000
          form.topViewPic = infoData.topViewPic
          form.sideViewPic = infoData.sideViewPic
          form.rearViewPic = infoData.rearViewPic
          form.frontViewPic = infoData.frontViewPic
          form.houseHeight = infoData.houseHeight / 1000
          if (infoData.houseFacing === 1) {
            form.houseFacing = "正南"
          } else if (infoData.houseFacing === 2) {
            form.houseFacing = "南偏东"
          } else if (infoData.houseFacing === 3) {
            form.houseFacing = "南偏西"
          }
          form.facingDegree = infoData.facingDegree
          form.gridConnectionDistance = infoData.gridConnectionDistance / 1000
          form.ridgeHigh = infoData.ridgeHigh / 1000
          if (infoData.roofingType === 1) {
            form.roofingType = "现浇板"
          } else if (infoData.roofingType === 2) {
            form.roofingType = "预制板"
          } else if (infoData.roofingType === 3) {
            form.roofingType = "木望板"
          } else if (infoData.roofingType === 4) {
            form.roofingType = "凛橼结构"
          }
          form.roofSlabThickness = infoData.roofSlabThickness / 1000
          form.woodPurlinDiameter = infoData.woodPurlinDiameter / 1000
          form.woodPurlinSpacing = infoData.woodPurlinSpacing / 1000
          form.woodWatchboardThickness = infoData.woodWatchboardThickness / 1000
          form.purlinDiameter = infoData.purlinDiameter / 1000
          form.purlinSpacing = infoData.purlinSpacing / 1000
          form.herringboneBeamEastWest = infoData.herringboneBeamEastWest / 1000
          form.herringboneBeamNorthSouth = infoData.herringboneBeamNorthSouth / 1000
          form.herringboneGirderDiameter = infoData.herringboneGirderDiameter / 1000
          if (infoData.installationMethod === 1) {
            form.installationMethod = "前支背拉"
          } else if (infoData.installationMethod === 2) {
            form.installationMethod = "挂钩"
          }
        }
      }
      // }
    })
    function handleInput(e) {
      // 通过正则过滤小数点后两位
      // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
      e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
    }

    function chooseRoofTypeShow(item) {
      form.roofingType = item
      roofTypeShow.value = false
    }

    function handleBodyClick() {
      houseOrientationTypeShow.value = false
      roofTypeShow.value = false
      installationModeTypeShow.value = false
    }
    function chooseHouseOrientationType(item) {
      form.houseFacing = item
      houseOrientationTypeShow.value = false
    }
    function chooseInstallationMode(item) {
      form.installationMethod = item
      installationModeTypeShow.value = false
    }

    function chooseSouthroofEnd(item) {
      form.SouthroofEnd = item
      SouthroofEndTypeShow.value = false
    }

    function chooseRidgeofRoofPokesOut(item) {
      form.RidgeofRoofPokesOut = item
      RidgeofRoofPokesOutTypeShow.value = false
    }

    function chooseNorthroofEnd(item) {
      form.NorthroofEnd = item
      NorthroofEndTypeShow.value = false
    }
    function onSubmit() {
      // 存储基本信息
      // 存入数据库厘米转毫米

      var copyForm = JSON.parse(JSON.stringify(form))
      for (const i in copyForm) {
        if (i !== 'houseFacing' && i !== 'facingDegree' && i !== 'roofingType' && i !== 'installationMethod' && i !== 'southSlope' && i !== 'northSlope' && i !== 'topViewPic' && i !== 'sideViewPic' && i !== 'rearViewPic' && i !== 'frontViewPic' && i !== 'roofType' && i !== 'id' && i !== 'roofID' && i !== 'SouthroofEnd' && i !== 'RidgeofRoofPokesOut' && i !== 'NorthroofEnd') {
          copyForm[i] = copyForm[i] * 1000
        }
      }
      if (copyForm.houseFacing === '正南') {
        copyForm.houseFacing = 1
        form.facingDegree = 0
        copyForm.facingDegree = 0
      } else if (copyForm.houseFacing === '南偏东') {
        copyForm.houseFacing = 2
      } else if (copyForm.houseFacing === '南偏西') {
        copyForm.houseFacing = 3
      }
      if (copyForm.installationMethod === '前支背拉') {
        copyForm.installationMethod = 1
      } else if (copyForm.installationMethod === '挂钩') {
        copyForm.installationMethod = 2
      }
      if (copyForm.roofingType === '现浇板') {
        copyForm.roofingType = 1
      } else if (copyForm.roofingType === '预制板') {
        copyForm.roofingType = 2
      } else if (copyForm.roofingType === '木望板') {
        copyForm.roofingType = 3
      } else {
        copyForm.roofingType = 4
      }
      if (copyForm.SouthroofEnd === '标准前支') {
        copyForm.SouthroofEnd = 1
      } else if (copyForm.SouthroofEnd === '无水平杆前支') {
        copyForm.SouthroofEnd = 2
      } else if (copyForm.SouthroofEnd === '前排挂钩') {
        copyForm.SouthroofEnd = 3
      } else if (copyForm.SouthroofEnd === '全挂钩') {
        copyForm.SouthroofEnd = 4
      }
      if (copyForm.RidgeofRoofPokesOut === '0mm') {
        copyForm.RidgeofRoofPokesOut = 1
      } else if (copyForm.RidgeofRoofPokesOut === '500mm') {
        copyForm.RidgeofRoofPokesOut = 2
      } else if (copyForm.RidgeofRoofPokesOut === '800mm') {
        copyForm.RidgeofRoofPokesOut = 3
      }
      if (copyForm.NorthroofEnd === '标准背拉') {
        copyForm.NorthroofEnd = 1
      } else if (copyForm.NorthroofEnd === '挂钩背拉') {
        copyForm.NorthroofEnd = 2
      }
      sessionStorage.setItem('baseInfo', JSON.stringify(form))
      sessionStorage.setItem('requestObliqueRoof', JSON.stringify(copyForm))
      emit('popupClose', '基本信息')
    }
    function cancelEvent() {
      emit('popupClose')
    }

    return {
      form,
      houseOrientationTypeShow,
      roofTypeShow,
      installationModeTypeShow,
      chooseSouthroofEnd,
      SouthroofEndTypeShow,
      RidgeofRoofPokesOutTypeShow,
      NorthroofEndTypeShow,
      chooseHouseOrientationType,
      chooseRoofTypeShow,
      chooseInstallationMode,
      chooseRidgeofRoofPokesOut,
      chooseNorthroofEnd,
      onSubmit,
      cancelEvent,
      handleInput,
      globleData,
      formatter
      // ...methodsMap
    }
  },

}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 448px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 700;
      color: #323233;
      width: 94px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }

    .options {
      z-index: 1;
      width: 94px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      font-weight: 700;
      color: #1A1A1A;
      li {
        line-height: 20px;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
    .threeline {
      height: 67px;
    }
    .fourline {
      height: 88px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>
